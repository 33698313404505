import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import "../../styles/Footer.scss";

export function Footer() {
  const navigate = useNavigate();

  function handlePrivacyPolicyClick(e) {
    e.preventDefault();
    navigate("/privacy-policy");
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  function handleTermsClick(e) {
    e.preventDefault();
    navigate("/terms-conditions");
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-content">
          <p onClick={handlePrivacyPolicyClick}>Privacy Policy</p>
          <p onClick={handleTermsClick}>Terms & Condition</p>
        </div>
        <div className="footer-support_content">
          <a href="mailto:support@artshift.ai?subject=Mail from ArtShift Site">
            Support
          </a>
          <a href="mailto:marketing@artshift.ai?subject=Mail from ArtShift Site">
            Marketing
          </a>
        </div>
      </div>
      <div className="social-network">
        <h3 className="social-title">Get in touch</h3>
        <div className="social-icon">
          <Link to="https://www.facebook.com/profile.php?id=61556217791400" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon className="icon" icon={faFacebookSquare} />
          </Link>
          <Link to="https://www.instagram.com/artshift.ai/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon className="icon" icon={faInstagram} />
          </Link>
        </div>
      </div>
      <div className="copyright">
        <p>COPYRIGHT &copy; 2024. ARTSHIFT - ALL RIGHTS RESERVED.</p>
        <p>POWERED BY CYPHER</p>
      </div>
    </footer>
  );
}
