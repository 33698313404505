import{ Nav } from "../../layouts/Nav/Nav"
import { Header } from "../../layouts/Header/Header";
import { Footer } from "../../layouts/Footer/Footer";
import { Video } from "../../components/Video/Video";
// import { ImgSlider } from "../../components/ImgSlider/ImgSlider";
import { Subscribe } from "../../components/Subscribe/Subscribe";
import "../../styles/HomePage.scss"

export function HomePage() {
  return (
    <div>
      <div className="home-container">
        <Nav />
        <Header />
        <Video />
        {/* <ImgSlider /> */}
        <Subscribe />
      </div>
     
      <Footer />
    </div>
  );
}
